import { KinositeSections } from 'app/types/common';
import { MENU_LINK_DEFAULT_SORT_ORDER } from 'app/constants/AppConstants';
import { reducer } from './js/app/reducer';
import './SchemeColor';

import notMovieLinkIcon from './resources/notMovieLinkIcon.html';
import { Qualifiers } from 'app/types/releases';

window.kinosite = {
	reducer,
	customization: {
		sectionTitles: () => ({
			[KinositeSections.SEANCES]: i18n.t("Schedule"),
			[KinositeSections.NOT_MOVIES]: notMovieLinkIcon
		}),
		menuLinksSortOrder: {
			[KinositeSections.AFISHA]: 1,
			[KinositeSections.SEANCES]: 2,
			[KinositeSections.NEWS]: 3,
			[KinositeSections.SOON]: 4,
			[KinositeSections.PUSHKIN_CARD]: 5,
			"/certificate": 6,
			[KinositeSections.NOT_MOVIES]: 7,
			[KinositeSections.VIP]: 8,
			[KinositeSections.CINEMAS]: MENU_LINK_DEFAULT_SORT_ORDER + 1
		},
		hiddenQualifiers: {
			[Qualifiers.for_kids]: true
		},
		notShowSoonButton: true
	}
};
